body {
  font-family: Arial, helvetica, sans-serif;
  overflow-y: hidden;
  transition: transform 0.5s linear;
  background: #ddd;
  background-position: center;
  background-size: cover;
}
header {
  z-index: 1000;
}

a:visited {
  color: #003366;
}

.hero {
  min-width: 100%;
  min-height: 100%;
}

#about {
  font-family: 'Archivo Black', sans-serif;
  z-index: 10000;
  width: 90%;
  height: 455px;
  position: absolute;
  background: white;
  border: solid black 4px;
  box-shadow: 5px 5px 30px 0px rgba(0,0,0,0.75);
}

#about p {
  padding: 6px;
  font-size: 0.5rem;
}
#about h3 {
  margin-bottom: 4px;
  margin-top: 0px;
  background: blue;
  color: white;
  padding-left: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
}

#about.invisible {
  visibility: hidden;
}
#thex {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 6px;
  font-weight: bolder;
}

#main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 255px;
  height: 500px;
  perspective: 500px;
  transform-style: preserve-3d;
  transition: transform 0.5s linear;
}

#container {
 margin: 0 auto;
 width: 100%;
 height: 100%;
 display: flex;
 align-items: center;
 overflow-y: hidden;
 justify-content: center;
}
.serious {
  font-family: 'Archivo Black', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
}
.image {
  background-color: red;
  overflow: hidden;
}

.project-name {
  background-color: blue;
  text-align: center;
}

.fit {
  width: 100%;
  font-size: 0.9rem;
  text-align: center;
}

.worlds-first {
  background-color: white;
  background-image: url("~/images/logo.png");
  background-size: 99% 99%;
  background-position: center;
  background-repeat: no-repeat;
  max-height: 200px;
}

.short-description {
  background-color: blue;
  font-size: 0.75rem;
}

.long-description {
  font-family: "Archivo Narrow", Arial, helvetic, sans-serif;
  font-size: 0.6em;
  overflow-y: hidden;
  font-weight: bold;
}
.project {
  display: block;
  position: absolute;
  border: solid black 4px;
  min-width: 256px;
  min-height: 455px;
  width: 256px;
  height: 455px;
  opacity: 1.0;
  transform: translate3d(0,0,0);
  transition: all 1s linear;
  background: white;
}

.footer {
  font-family: 'Archivo Black', sans-serif;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #ccc;
  font-size: 0.5rem;
  text-align: center;
}

.footer a{
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 5px;
}

.cover {
 background-size: cover !important;
}

.mw5 {
 max-width: 16rem;
}

.pt4 {
 padding-top: 2rem;
}

.mb4 {
 margin-bottom: 2rem;
}

.center {
 margin-right: auto;
 margin-left: auto;
}

.a {
  transform: rotateY(0deg) rotateX(360deg) translateX(0px);
  background: white;
  box-shadow: 5px 5px 30px 0px rgba(0,0,0,0.75);
}
.b {
  transform: translate3d(0, 0, 0);
  transform: rotateY(0deg) translateY(-422px) translateZ(-114px) rotateX(30deg);
  background: white;
}
.c {
  transform: rotateY(0deg) rotateX(90deg) translateZ(-200px) translateY(-500px) scale(0.1);
  background: white;
}
.d {
 display: none;
}
.e {
 display: none;
}
.f {
 display: none;
}
.g {
 transform: rotateY(0deg) rotateX(270deg) translateZ(873px) translateY(639px) scale(0.1);
 background: white;
}
.h {
  transform: rotateY(0deg) rotateX(270deg) translateZ(873px) translateY(639px) scale(0.1);
  background: white;
}
.i {
  transform: rotateY(0deg) rotateX(330deg) translateZ(114px) translateY(422px) scale(1.0);
  background: white;
}

.ticker {
  font-family: 'Archivo Black', sans-serif;
  font-size: 1rem;
  font-weight: 10000;
}

.footer-links {
  font-family: 'Archivo Black', sans-serif;
  font-size: .85rem;
  font-weight: bold;
}

.footer-links a{
  color: blue;
  text-decoration: underline;
}


 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
   .project {
     min-width: 400px;
     min-height: 640px;
     width: 400px;
     height: 640px;
     top: auto;
    }
    .fit {
      font-size: 1.3rem;
    }

    #main {
      width: 400px;
      height: 900px;
      perspective: 500px;
      min-height: 640px;
    }
    #container {
      height: 100vh;
    }
    .a {
      transform: rotateY(0deg) rotateX(360deg) translateX(0px);
      background: white;
    }
    .b {
      transform: rotateY(0deg) translateY(-596px) translateZ(-160px) rotateX(30deg);
      background: white;
    }
    .c {
      transform: rotateY(0deg) rotateX(90deg) translateZ(873px) translateY(-639px) scale(0.1);
      background: white;
    }
   .d {
     transform: rotateY(0deg) rotateX(150deg) translateY(-360px) translateZ(360px);
     background: white;
     display: none;
   }
   .e {
     transform: rotateY(88deg) rotateX(180deg) translateZ(720px);
     background: white;
     display: none;
   }
   .f {
     transform: rotateY(88deg) rotateX(210deg) translateZ(1440px);
     background: white;
     display: none;
   }
   .g {
     transform: rotateY(0deg) rotateX(270deg) translateZ(873px) translateY(639px) scale(0.1);
     background: white;
   }
   .h {
     transform: rotateY(0deg) rotateX(270deg) translateZ(873px) translateY(639px) scale(0.1);
     background: white;
   }
   .i {
     transform: rotateY(0deg) rotateX(330deg) translateZ(160px) translateY(595px) scale(1.0);
     background: white;
   }

   #about {
     min-width: 450px;
     min-height: 640px;
     width: 450px;
     height: 640px;
   }

   #about p {
    font-size: 0.75rem;
    padding: 8px;
   }
   .ticker {
       font-size: 2rem;
       font-weight: 10000;
     }

     .footer {
       font-size: 0.75rem;
     }
     .footer-links {
       font-size: 2rem;
     }
     .long-description {
       font-size: 0.9rem;
     }
     .short-description {
       font-size: 0.8rem;
     }
 }
